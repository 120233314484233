<template>
	<div class="grid">
		<div class="col-12 xl:col-6">
			<div class="card">
				<div class="flex justify-content-between align-items-center">
					<h5>事件类型统计</h5>
					<div>
						<Button label="昨日"
							:class="[chartIndex == 0 ? 'p-button-rounded p-button-info mr-2 mb-2' : 'p-button-text p-button-secondary mr-2 mb-2']"
							@click="onChartStatChange(0)" />
						<Button label="今日"
							:class="[chartIndex == 1 ? 'p-button-rounded p-button-info mr-2 mb-2' : 'p-button-text p-button-secondary mr-2 mb-2']"
							@click="onChartStatChange(1)" />
						<Button label="本周"
							:class="[chartIndex == 2 ? 'p-button-rounded p-button-info mr-2 mb-2' : 'p-button-text p-button-secondary mr-2 mb-2']"
							@click="onChartStatChange(2)" />
						<Button label="本月"
							:class="[chartIndex == 3 ? 'p-button-rounded p-button-info mr-2 mb-2' : 'p-button-text p-button-secondary mr-2 mb-2']"
							@click="onChartStatChange(3)" />
					</div>
				</div>
				<div class="flex flex-column align-items-center">
					<Chart type="pie" :data="chartStatisticData" :options="pieOptions" style="width: 70%" />
				</div>
			</div>
		</div>
		<div class="col-12 xl:col-6">
			<div class="card">
				<div class="flex justify-content-between align-items-center">
					<h5>趋势图（总抓拍数）</h5>
					<div>
						<Button label="1天"
							:class="[eventDailyTimeUnit == 0 ? 'p-button-rounded p-button-info mr-2 mb-2' : 'p-button-text p-button-secondary mr-2 mb-2']"
							@click="onEventDailyTimeUnitChange(0)" />
						<Button label="7天"
							:class="[eventDailyTimeUnit == 1 ? 'p-button-rounded p-button-info mr-2 mb-2' : 'p-button-text p-button-secondary mr-2 mb-2']"
							@click="onEventDailyTimeUnitChange(1)" />
						<Button label="30天"
							:class="[eventDailyTimeUnit == 2 ? 'p-button-rounded p-button-info mr-2 mb-2' : 'p-button-text p-button-secondary mr-2 mb-2']"
							@click="onEventDailyTimeUnitChange(2)" />
					</div>
				</div>
				<div class="flex flex-column align-items-center">
					<Chart type="line" :data="eventTrend" :options="lineOptions" style="width: 100%" />
				</div>
			</div>
			<div class="card">
				<div class="flex justify-content-between align-items-center">
					<h5>趋势图（违规数）</h5>
					<div>
						<Button label="1天"
							:class="[eventDailyUnSafeTimeUnit == 0 ? 'p-button-rounded p-button-info mr-2 mb-2' : 'p-button-text p-button-secondary mr-2 mb-2']"
							@click="onEventDailyUnSafeTimeUnitChange(0)" />
						<Button label="7天"
							:class="[eventDailyUnSafeTimeUnit == 1 ? 'p-button-rounded p-button-info mr-2 mb-2' : 'p-button-text p-button-secondary mr-2 mb-2']"
							@click="onEventDailyUnSafeTimeUnitChange(1)" />
						<Button label="30天"
							:class="[eventDailyUnSafeTimeUnit == 2 ? 'p-button-rounded p-button-info mr-2 mb-2' : 'p-button-text p-button-secondary mr-2 mb-2']"
							@click="onEventDailyUnSafeTimeUnitChange(2)" />
					</div>
				</div>
				<div class="flex flex-column align-items-center">
					<Chart type="line" :data="eventUnSafeTrend" :options="lineOptions" style="width: 100%" />
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import EventBus from '@/AppEventBus';
import ProductService from '../service/ProductService';
import dateUtil from '../utils/dateUtil.js'

export default {
	data() {
		return {

			seriesColor: [
				"#FF6384",
				"#36A2EB",
				"#FFCE56",
				"#A52A2A",
				"#228B22",
				"#8A2BE2",
				"#FF8C00",
				"#DAA520",
				"#FF00FF",
				"#8B008B",
				"#00CED1",
				"#FF4500",
				"#7FFF00",
				"#FF6384",
				"#36A2EB",
				"#FFCE56",
				"#A52A2A",
				"#228B22",
				"#8A2BE2",
				"#FF8C00",
				"#DAA520",
				"#FF00FF",
				"#8B008B",
				"#00CED1",
				"#FF4500",
				"#7FFF00"
			],

			// 事件类型统计数据
			pieOptions: {
				plugins: {
					legend: {
						labels: {
							color: '#495057',
							boxWidth: 40
						},
						position: 'right',
					}
				}
			},
			chartIndex: -1,
			todayChartStatData: {
				labels: [],
				data: []
			},
			yesterdayChartStatData: {
				labels: [],
				data: []
			},
			thisWeekChartStatData: {
				labels: [],
				data: []
			},
			thisMonthChartStatData: {
				labels: [],
				data: []
			},
			chartStatisticData: {
				labels: [],
				datasets: [
					{
						data: [],
						backgroundColor: [],
						hoverBackgroundColor: []
					}
				]
			},

			eventDailyData: null,
			eventDailyTimeUnit: 0, //0:天；1:周；2:月
			eventDailyUnSafeTimeUnit: 0, //0:天；1:周；2:月

			//趋势图数据
			lineOptions: {
				plugins: {
					legend: {
						labels: {
							color: '#495057'
						},
						position: 'bottom'
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color: '#ebedef',
						}
					},
					y: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color: '#ebedef',
						}
					},
				}
			},
			trendIndex: 0,
			eventTrend: {
				labels: [],
				datasets: [],
			},
			eventUnSafeTrend: {
				labels: [],
				datasets: [],
			},
		}
	},
	productService: null,
	themeChangeListener: null,
	mounted() {
		this.getChartStatistics();
		this.getEventDaily();
	},
	beforeUnmount() {
		EventBus.off('change-theme', this.themeChangeListener);
	},
	created() {
		this.productService = new ProductService();
	},
	methods: {
		errorMessage(msg) {
			this.$toast.add({ severity: 'error', summary: '发生错误', detail: msg, life: 3000 });
		},
		//根据Daily数据，获取Pie图显示
		calcChartData(o_data) {
			let dailyObj = new Object();
			for (let i = 0; i < o_data.dates.length; i++) {
				//console.log(res.data.dates[i])f
				//console.log(res.data[res.data.dates[i]])
				let dateObj = o_data[o_data.dates[i]];

				for (let v in dateObj) {
					if (dailyObj[v] === undefined) {
						dailyObj[v] = new Array();
					}

					//dailyObj[v].push(dateObj[v].unsafe)
					dailyObj[v].push(dateObj[v].total)
				}
			}

			var names = [];
			var data = [];
			for (let v in dailyObj) {
				names.push(v)

				let sum = 0;
				for (let i = 0; i < dailyObj[v].length; i++) {
					sum = sum + dailyObj[v][i];
				}
				data.push(sum);
			}

			return { names, data };
		},

		requestEventDaily(start, end) {
			let enterprise_id = this.enterpriseId;

			return this.$http('/api/admin/event/daily', {
				enterprise_id: enterprise_id,
				status: 3,						//状态为2表示获取违规数据，为3表示总数
				time: start,
				times: end,
			}).then(res => {
				if (res.code == 200) {
					return res.data;
				}
				else {
					this.errorMessage(res.msg);
					return null;
				}
			})
		},

		async getChartStatistics() {
			//获取昨日、今日、本周、本月的数据
			let today = new Date(new Date().setHours(0, 0, 0, 0));
			let yesterday = dateUtil.yesterday();
			let ws = dateUtil.weekStartDate();
			let we = dateUtil.weekEndDate();
			let ms = dateUtil.monthStartDate();
			let me = dateUtil.monthEndDate();

			this.todayDailyStatData = await this.requestEventDaily(today.getTime(), today.getTime() + 86399999);
			this.yesterdayDailyStatData = await this.requestEventDaily(yesterday.getTime(), yesterday.getTime() + 86399999);
			this.thisWeekDailyStatData = await this.requestEventDaily(ws.getTime(), we.getTime() + 86399999);
			this.thisMonthDailyStatData = await this.requestEventDaily(ms.getTime(), me.getTime() + 86399999);

			let d1 = this.calcChartData(this.todayDailyStatData)
			let d2 = this.calcChartData(this.yesterdayDailyStatData)
			let d3 = this.calcChartData(this.thisWeekDailyStatData)
			let d4 = this.calcChartData(this.thisMonthDailyStatData)

			this.todayChartStatData.labels = d1.names;
			this.todayChartStatData.data = d1.data;
			this.yesterdayChartStatData.labels = d2.names;
			this.yesterdayChartStatData.data = d2.data;
			this.thisWeekChartStatData.labels = d3.names;
			this.thisWeekChartStatData.data = d3.data;
			this.thisMonthChartStatData.labels = d4.names;
			this.thisMonthChartStatData.data = d4.data;

			if (this.chartIndex == -1) {
				this.chartIndex = 1;
				this.onChartStatChange(1);
			}
		},

		onChartStatChange(index) {
			switch (index) {
				case 0:
					this.chartStatisticData.labels = this.yesterdayChartStatData.labels;
					this.chartStatisticData.datasets[0].data = this.yesterdayChartStatData.data;
					break;
				case 1:
					this.chartStatisticData.labels = this.todayChartStatData.labels;
					this.chartStatisticData.datasets[0].data = this.todayChartStatData.data;
					break;
				case 2:
					this.chartStatisticData.labels = this.thisWeekChartStatData.labels;
					this.chartStatisticData.datasets[0].data = this.thisWeekChartStatData.data;
					break;
				case 3:
					this.chartStatisticData.labels = this.thisMonthChartStatData.labels;
					this.chartStatisticData.datasets[0].data = this.thisMonthChartStatData.data;
					break;
			}

			this.chartStatisticData.datasets[0].backgroundColor = this.seriesColor;
			this.chartStatisticData.datasets[0].hoverBackgroundColor = this.seriesColor;
			this.chartIndex = index;
		},

		onEventDailyTimeUnitChange(u) {
			this.eventDailyTimeUnit = u;
			this.changeEventDailyStyle(3);
		},

		onEventDailyUnSafeTimeUnitChange(u) {
			this.eventDailyUnSafeTimeUnit = u;
			this.changeEventDailyStyle(2);
		},

		changeEventDailyStyle(type) {

			if (type == 3) {
				this.eventTrend.labels.length = 0;
				this.eventTrend.datasets.length = 0;
			}
			else {
				this.eventUnSafeTrend.labels.length = 0;
				this.eventUnSafeTrend.datasets.length = 0;
			}

			var categories = [];

			//总共按能同时放12个点计算
			var could_show = false;
			var add_cnt = 1;

			let _unit = 0;

			if (type == 3)
				_unit = this.eventDailyTimeUnit;
			else
				_unit = this.eventDailyUnSafeTimeUnit;

			for (let i = 0; i < 3; i++) {
				if (_unit == 0) {
					//如果在12个点内，则正常显示，如果超出，则转为周或者月
					if (this.eventDailyData.dates.length <= 12) {
						categories = this.eventDailyData.dates.map((e) => {
							var dtarr = e.split('-');
							return dtarr[1] + '-' + dtarr[2];
						});

						could_show = true;
						break;
					} else {
						_unit = 1;
						continue;
					}
				} else if (_unit == 1) {
					//计算有多少个周，如果在12个周以内，则正常显示，否则转为月
					var weekCnt = this.eventDailyData.dates.length / 7;

					if (weekCnt <= 12) {
						for (let i = 0; i < weekCnt; i++) {
							let dt = this.eventDailyData.dates[i * 7]
							if (dt != undefined) {
								let dtarr = dt.split('-');
								categories.push(dtarr[1] + '-' + dtarr[2])
							}
						}

						could_show = true;
						add_cnt = 7;
						break;
					} else {
						_unit = 2;
						continue;
					}
				} else {
					//计算有多少个周，如果在12个周以内，则正常显示，否则转为月
					var monthCnt = this.eventDailyData.dates.length / 30;

					if (monthCnt <= 12) {
						for (let i = 0; i < monthCnt; i++) {
							let dt = this.eventDailyData.dates[i * 30]
							if (dt != undefined) {
								let dtarr = dt.split('-');
								categories.push(dtarr[1] + '-' + dtarr[2])
							}
						}

						could_show = true;
						add_cnt = 30;
						break;
					} else {

						break;
					}
				}
			}


			if (type == 3)
				this.eventDailyTimeUnit = _unit;
			else
				this.eventDailyUnSafeTimeUnit = _unit;


			if (!could_show) {
				this.errorMessage('时间跨度不能超过一年');
				return;
			}

			if (type == 3)
				this.eventTrend.labels = categories;
			else {
				this.eventUnSafeTrend.labels = categories;
			}

			console.log(categories)

			let dailyObj = new Object();

			//创建所有标签
			for (let i = 0; i < this.eventDailyData.dates.length; i++) {
				let dateObj = this.eventDailyData[this.eventDailyData.dates[i]];

				for (let v in dateObj) {
					if (dailyObj[v] === undefined) {
						dailyObj[v] = new Array();
					}
				}
			}

			for (let v in dailyObj) {
				for (let i = 0; i < this.eventDailyData.dates.length; i++) {
					dailyObj[v].push(0);
				}
			}

			for (let i = 0; i < this.eventDailyData.dates.length; i++) {
				let dateObj = this.eventDailyData[this.eventDailyData.dates[i]];

				for (let v in dateObj) {
					if (type == 3)
						dailyObj[v][i] = dateObj[v].total;
					else
						dailyObj[v][i] = dateObj[v].unsafe;
				}
			}

			let idx = 0;
			for (let v in dailyObj) {
				var tmp_arr = new Array();
				for (let i = 0; i < dailyObj[v].length;) {
					var this_cnt = 0;
					for (let j = 0; j < add_cnt; j++) {
						this_cnt = this_cnt + dailyObj[v][i];
						i = i + 1;
						if (i >= dailyObj[v].length)
							break;
					}
					tmp_arr.push(this_cnt);
				}

				dailyObj[v] = tmp_arr;

				let obj = new Object();
				obj.label = v;
				obj.data = dailyObj[v];
				obj.backgroundColor = this.seriesColor[idx];
				obj.hoverBackgroundColor = this.seriesColor[idx];
				obj.borderColor = this.seriesColor[idx];
				obj.tension = 0.4;

				if (type == 3)
					this.eventTrend.datasets.push(obj)
				else
					this.eventUnSafeTrend.datasets.push(obj)

				idx = idx + 1;
			}
		},

		getEventDaily() {
			let enterprise_id = '';
			var ms = dateUtil.monthStartDate();
			var me = dateUtil.monthEndDate();

			this.$http('/api/admin/event/daily', {
				enterprise_id: enterprise_id,
				time: ms.getTime(),
				times: me.getTime() + 86399999
			}).then(res => {
				if (res.code == 200) {
					this.eventDailyData = res.data;
					this.changeEventDailyStyle(0);
					this.changeEventDailyStyle(3);
				} else {
					this.errorMessage(res.msg)
				}
			})
		},
	}
}
</script>

<style scoped lang="scss">
@import '../assets/demo/badges.scss';
</style>
